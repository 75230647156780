/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import { CommerceError } from '@commerce/utils/errors'
import useMultiLogin, {
  UseMultiLogin,
} from '@commerce/auth/use-multipass-login'
import type { LoginMultiHook } from '../types/login'
import useCustomer from '../customer/use-customer'

import {
  setCustomerToken,
  throwUserErrors,
  customerAccessTokenCreateWithMultipass,
} from '../utils'
import {
  Mutation,
  MutationCustomerAccessTokenCreateWithMultipassArgs,
} from '../schema'

export default useMultiLogin as UseMultiLogin<typeof handler>

export const handler: MutationHook<LoginMultiHook> = {
  fetchOptions: {
    query: customerAccessTokenCreateWithMultipass,
  },
  async fetcher({ input: { multipassToken }, options, fetch }) {
    if (!multipassToken) {
      throw new CommerceError({
        message: 'An email and password are required to login',
      })
    }

    const { customerAccessTokenCreate } = await fetch<
      Mutation,
      MutationCustomerAccessTokenCreateWithMultipassArgs
    >({
      ...options,
      variables: {
        input: { multipassToken },
      },
    })

    throwUserErrors(customerAccessTokenCreate?.customerUserErrors)

    const customerAccessToken = customerAccessTokenCreate?.customerAccessToken
    const accessToken = customerAccessToken?.accessToken

    if (accessToken) {
      setCustomerToken(accessToken, options.locale)
    }

    return null
  },
  useHook:
    ({ fetch }) =>
    () => {
      const { mutate } = useCustomer()

      return useCallback(
        async function login(input) {
          const data = await fetch({ input })
          await mutate(data, false)
          return data
        },
        [mutate]
      )
    },
}
