import { useHook, useMutationHook } from '../utils/use-hook'
import { mutationFetcher } from '../utils/default-fetcher'
import type { HookFetcherFn, MutationHook } from '../utils/types'
import type { RecoverHook } from '../types/recover'
import type { Provider } from '..'

export type UseRecover<
  H extends MutationHook<RecoverHook<any>> = MutationHook<RecoverHook>
> = ReturnType<H['useHook']>

export const fetcher: HookFetcherFn<RecoverHook> = mutationFetcher

const fn = (provider: Provider) => provider.auth?.useRecover!

const useRecover: UseRecover = (...args) => {
  const hook = useHook(fn)
  return useMutationHook({ fetcher, ...hook })(...args)
}

export default useRecover
