import { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { BRAND } from '@shopify/const'
import { CreditInfo } from '../types'
import { useAlpcMutation } from './useAlpcFetch'

function useCreditInfo() {
  const { locale = '' } = useRouter()

  const [creditInfo, setCreditInfo] = useState<CreditInfo>()

  const { isMutating: isLoading, trigger } = useAlpcMutation<
    { data: { credit_infos: CreditInfo[] } },
    { user_id?: string }
  >(
    {
      url: `/v1/credit/info`,
      initData: {
        brand: BRAND,
      },
    },
    {
      onSuccess(responseData) {
        const info = responseData?.data?.credit_infos?.find(
          (item: CreditInfo) => item.brand === BRAND
        )

        setCreditInfo(info)
      },
    }
  )

  const getCreditInfo = useCallback(
    async (userId?: string) => {
      trigger({
        user_id: userId,
      })
    },
    [trigger]
  )

  return {
    creditInfo,
    isLoading,
    getCreditInfo,
  }
}

export default useCreditInfo
