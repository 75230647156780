import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useContext,
  useState,
  useMemo,
} from 'react'
import fetcher from '@lib/fetcher'
import { useRouter } from 'next/router'
import { getAppName } from '@components/common/Registrations/const'
import { getMultipassToken } from '@shopify/utils'
import Cookies from 'js-cookie'

interface Profile {
  user_id: string
  email: string
  last_name?: string
  first_name?: string
  dob?: string
  activated: boolean
}

interface ProfileContextValue {
  isLoading: boolean
  isFinish: boolean
  profile?: Profile
}

const contextInitialValue: ProfileContextValue = {
  isLoading: false,
  isFinish: false,
}

const ProfileContext = createContext<ProfileContextValue>(contextInitialValue)

export function ProfileProvider({ children }: PropsWithChildren<{}>) {
  const { locale } = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [isFinish, setIsFinish] = useState(false)
  const [profile, setProfile] = useState<Profile>()

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const removeProfile = useCallback(() => {
    setIsLoading(true)
    setProfile(undefined)
    Cookies.set(`${app}-info`, '', {
      Domain: '.anker.com',
      expires: new Date('1970-01-01'),
    })
    Cookies.set('multipassinfo', '', {
      Domain: '.anker.com',
      expires: new Date('1970-01-01'),
    })
    setTimeout(() => {
      setIsLoading(false)
    })
  }, [app])

  const fetchProfile = useCallback(async () => {
    setIsLoading(true)

    const multipassinfo = getMultipassToken()

    if (!multipassinfo) {
      removeProfile()
      setIsFinish(true)
      return
    }

    const response = await fetcher({
      action: '',
      method: 'GET',
      url: '/api/multipass/account/users/profile',
      body: {},
      locale,
      headers: {},
      repeatKey: '',
      type: '',
    })

    if (response?.status === 200) {
      const profileData = response.data?.data
      setProfile(profileData)
    } else {
      setProfile(undefined)
    }
    setIsLoading(false)
    setIsFinish(true)
  }, [locale, removeProfile])

  useEffect(() => {
    fetchProfile()
  }, [fetchProfile])

  return (
    <ProfileContext.Provider value={{ isLoading, isFinish, profile }}>
      {children}
    </ProfileContext.Provider>
  )
}

export const useProfile = () => useContext(ProfileContext)
