import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const COOKIE_NAME = 'accept_cookies'

export const useAcceptCookies = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  useEffect(() => {
    try {
      if (!Cookies.get(COOKIE_NAME)) {
        setAcceptedCookies(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const acceptCookies = () => {
    setAcceptedCookies(true)
    try {
      Cookies.set(COOKIE_NAME, 'accepted', {
        Domain: window.location.hostname,
        expires: 365,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
  }
}
