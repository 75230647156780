import metafieldsItems from './metafields-items-query'

export const checkoutDetailsFragment = /* GraphQL */ `
  fragment checkoutDetails on Checkout {
    id
    webUrl
    subtotalPriceV2 {
      amount
      currencyCode
    }
    totalTaxV2 {
      amount
      currencyCode
    }
    totalPriceV2 {
      amount
      currencyCode
    }
    lineItemsSubtotalPrice {
      amount
      currencyCode
    }
    totalDuties {
      amount
      currencyCode
    }
    ready
    orderStatusUrl
    completedAt
    createdAt
    taxesIncluded
    customAttributes {
      key
      value
    }
    discountApplications(first: 10) {
      edges {
        node {
          value {
            ... on MoneyV2 {
              amount
              currencyCode
            }
            ... on PricingPercentageValue {
              percentage
            }
          }
        }
      }
    }
    lineItems(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            allocatedAmount {
              amount
              currencyCode
            }
            discountApplication{
              allocationMethod
              targetSelection
              targetType
              value
            }
          }
          variant {
            id
            sku
            title
            availableForSale
            quantityAvailable
            currentlyNotInStock
            selectedOptions {
              name
              value
            }
            image {
              originalSrc
              altText
              width
              height
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            product {
              handle
              tags
            }
            MF_cartInfo: metafield(namespace: "cart", key: "info") {
							${metafieldsItems}
            }
          }
        }
      }
    }
  }
`

const getCheckoutQuery = /* GraphQL */ `
  query getCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...checkoutDetails
    }
  }
  ${checkoutDetailsFragment}
`
export default getCheckoutQuery
