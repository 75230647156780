import Cookies from 'js-cookie'

import {
  SHOPIFY_CHECKOUT_ID_COOKIE,
  SHOPIFY_CHECKOUT_URL_COOKIE,
  SHOPIFY_COOKIE_EXPIRE,
} from '../const'

import checkoutCreateMutation from './mutations/checkout-create'
import {
  CheckoutCreatePayload,
  CheckoutLineItemInput,
  Mutation,
  MutationCheckoutCreateArgs,
} from '../schema'
import { FetcherOptions } from '@commerce/utils/types'

export const checkoutCreate = async (
  fetch: <T = any, B = Body>(options: FetcherOptions<B>) => Promise<T>,
  lineItems: CheckoutLineItemInput[],
  locale = 'us'
): Promise<CheckoutCreatePayload> => {
  const { checkoutCreate } = await fetch<Mutation, MutationCheckoutCreateArgs>({
    locale,
    query: checkoutCreateMutation,
    variables: {
      input: {
        lineItems,
      },
    },
  })

  const checkout = checkoutCreate?.checkout
  const checkoutUrlCookie = SHOPIFY_CHECKOUT_URL_COOKIE[locale]
  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]

  if (checkout) {
    const checkoutId = checkout?.id
    const options = {
      Domain: window.location.hostname,
      SameSite: 'Strict',
      Secure: true,
      expires: SHOPIFY_COOKIE_EXPIRE,
    }
    Cookies.set(checkoutIdCookie, checkoutId, options)
    if (checkout?.webUrl) {
      Cookies.set(checkoutUrlCookie, checkout.webUrl, options)
    }
  }

  return checkoutCreate!
}

export default checkoutCreate
