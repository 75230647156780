import { SHOPIFY_CHECKOUT_ID_COOKIE } from './const'

import { handler as useCart } from './cart/use-cart'
import { handler as useAddItem } from './cart/use-add-item'
import { handler as useUpdateItem } from './cart/use-update-item'
import { handler as useRemoveItem } from './cart/use-remove-item'
import { handler as useCodeApply } from './cart/use-code-apply'
import { handler as useRemoveCode } from './cart/use-remove-code'

import { handler as useCustomer } from './customer/use-customer'
import { handler as useSearch } from './product/use-search'
import { handler as useProduct } from './product/use-product'

import { handler as useLogin } from './auth/use-login'
import { handler as useMultiLogin } from './auth/use-multipass-login'
import { handler as useLogout } from './auth/use-logout'
import { handler as useSignup } from './auth/use-signup'
import { handler as useRecover } from './auth/use-recover'

import fetcher from './fetcher'

export const shopifyProvider = {
  locale: 'us',
  shop: {},
  cartCookie: SHOPIFY_CHECKOUT_ID_COOKIE,
  fetcher,
  cart: {
    useCart,
    useAddItem,
    useUpdateItem,
    useRemoveItem,
    useCodeApply,
    useRemoveCode,
  },
  customer: { useCustomer },
  products: { useSearch, useProduct },
  auth: { useLogin, useMultiLogin, useLogout, useSignup, useRecover },
}

export type ShopifyProvider = typeof shopifyProvider
