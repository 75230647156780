
import Cookies from 'js-cookie'
import type { Cart } from '../types/cart'
import { atobID } from './normalize'
import { CommerceError } from '@commerce/utils/errors'

import {
  SHOPIFY_CART_COOKIE,
} from '../const'

import {
  CheckoutLineItemsAddPayload,
  CheckoutLineItemsRemovePayload,
  CheckoutLineItemsUpdatePayload,
  CheckoutCreatePayload,
  CheckoutUserError,
  Checkout,
  Maybe,
} from '../schema'

import { normalizeCart } from './normalize'
import throwUserErrors from './throw-user-errors'

export type CheckoutQuery = {
  checkout: Checkout
  checkoutUserErrors?: Array<CheckoutUserError>
}

export type CheckoutPayload =
  | CheckoutLineItemsAddPayload
  | CheckoutLineItemsUpdatePayload
  | CheckoutLineItemsRemovePayload
  | CheckoutCreatePayload
  | CheckoutQuery

const checkoutToCart = (checkoutPayload?: Maybe<CheckoutPayload>, locale?: string): Cart => {
  throwUserErrors(checkoutPayload?.checkoutUserErrors)

  const shopifyCartCookie = SHOPIFY_CART_COOKIE[locale || 'us']
  const hostUrl = window.location.host.split(".");
  if (!checkoutPayload?.checkout) {
    const getCartCookie = Cookies.get(shopifyCartCookie)
    const cartCookie = getCartCookie ? JSON.parse(getCartCookie) : {}
    if (cartCookie?.from === 'shopifyTheme') Cookies.remove(shopifyCartCookie);
    throw new CommerceError({
      message: 'Missing checkout object from response',
    })
  }
  const cart = normalizeCart(checkoutPayload?.checkout);
  Cookies.set(
    shopifyCartCookie,
    JSON.stringify({
      from: "shopifyStorefront",
      items: cart.lineItems.map((item) => ({
        id: atobID(item.variantId),
        quantity: item.quantity,
      })),
    }),
    {
      domain: hostUrl.length > 2 ? `.${hostUrl[1]}.${hostUrl[2]}` : window.location.hostname,
      expires: 14
    }
  );

  return cart
}

export default checkoutToCart
