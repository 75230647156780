import { useMemo } from 'react'
import Cookies from 'js-cookie'
import useCommerceCart, { UseCart } from '@commerce/cart/use-cart'
import { SWRHook } from '@commerce/utils/types'
import { checkoutCreate, checkoutToCart, btoaID } from '../utils'
import getCheckoutQuery from '../utils/queries/get-checkout-query'
import { GetCartHook } from '../types/cart'

import {
  SHOPIFY_CART_COOKIE,
  SHOPIFY_CHECKOUT_ID_COOKIE,
  SHOPIFY_CHECKOUT_URL_COOKIE,
  SHOPIFY_COOKIE_EXPIRE,
} from '../const'

export default useCommerceCart as UseCart<typeof handler>

export const handler: SWRHook<GetCartHook> = {
  fetchOptions: {
    query: getCheckoutQuery,
  },
  async fetcher({ input: { cartId }, options, fetch }) {
    const { locale = 'us' } = options
    const checkoutUrlCookie = SHOPIFY_CHECKOUT_URL_COOKIE[locale]
    const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
    const shopifyCartCookie = SHOPIFY_CART_COOKIE[locale]

    const getCartCookie = Cookies.get(shopifyCartCookie)
    const cartCookie = getCartCookie ? JSON.parse(getCartCookie) : {}

    const cookies_options = {
      Domain: window?.location?.hostname,
      SameSite: 'Strict',
      Secure: true,
      expires: SHOPIFY_COOKIE_EXPIRE,
    }
    // console.log('fetch:')

    const hostUrl = window.location.host.split('.')

    if (
      cartCookie?.from === 'shopifyTheme' ||
      cartId?.includes('shopify/Cart') ||
      cartId?.includes('shopify/cart') ||
      checkoutIdCookie?.includes('shopify/cart') ||
      checkoutIdCookie?.includes('shopify/Cart')
    ) {
      if (cartCookie?.items?.length > 0) {
        const lineItems = cartCookie.items.map(
          (item: { id: string; quantity: string }) => ({
            variantId: btoaID(item.id),
            quantity: item.quantity,
          })
        )
        return checkoutToCart(
          await checkoutCreate(fetch, lineItems, locale),
          locale
        )
      } else {
        Cookies.remove(checkoutIdCookie, cookies_options)
        Cookies.remove(checkoutUrlCookie, cookies_options)
        Cookies.remove(shopifyCartCookie, {
          domain:
            hostUrl.length > 2
              ? `.${hostUrl[1]}.${hostUrl[2]}`
              : window?.location?.hostname,
          expires: 14,
        })
        return null
      }
    } else if (cartId) {
      const { node: checkout } = await fetch({
        ...options,
        variables: {
          checkoutId: cartId,
        },
      })
      if (!!checkout?.completedAt) {
        Cookies.remove(checkoutIdCookie, cookies_options)
        Cookies.remove(checkoutUrlCookie, cookies_options)
        Cookies.remove(shopifyCartCookie, {
          domain:
            hostUrl.length > 2
              ? `.${hostUrl[1]}.${hostUrl[2]}`
              : window?.location?.hostname,
          expires: 14,
        })
        return null
      }
      // console.log(checkout)
      return checkoutToCart({ checkout }, locale)
    } else {
      Cookies.remove(checkoutIdCookie, cookies_options)
      Cookies.remove(checkoutUrlCookie, cookies_options)
      Cookies.remove(shopifyCartCookie, {
        domain:
          hostUrl.length > 2
            ? `.${hostUrl[1]}.${hostUrl[2]}`
            : window?.location?.hostname,
        expires: 14,
      })
    }
    return null
  },
  useHook:
    ({ useData }) =>
    (input) => {
      const response = useData({
        swrOptions: { revalidateOnFocus: false, ...input?.swrOptions },
      })
      return useMemo(
        () =>
          Object.create(response, {
            isEmpty: {
              get() {
                return (response.data?.lineItems.length ?? 0) <= 0
              },
              enumerable: true,
            },
          }),
        [response]
      )
    },
}
