/* eslint-disable react-hooks/rules-of-hooks */
import useCustomer, { UseCustomer } from '@commerce/customer/use-customer'
import type { CustomerHook } from '../types/customer'
import { SWRHook } from '@commerce/utils/types'
import { SHOPIFY_COOKIE_EXPIRE } from '@shopify/const'
import {
  getCustomerQuery,
  getCustomerToken,
  getMultipassToken,
  removeMultipassToken,
  setCustomerToken,
  throwUserErrors,
  customerAccessTokenCreateWithMultipass,
} from '../utils'
import Cookies from 'js-cookie'
import {
  GetCustomerQuery,
  GetCustomerQueryVariables,
  Mutation,
  MutationCustomerAccessTokenCreateWithMultipassArgs,
} from '../schema'

export default useCustomer as UseCustomer<typeof handler>

export const handler: SWRHook<CustomerHook> = {
  fetchOptions: {
    query: getCustomerQuery,
  },
  async fetcher({ options, fetch }) {
    const locale = options.locale || 'us'
    const multipassToken = getMultipassToken()
    const customerAccessToken = getCustomerToken(locale)
    const getDomain = () => {
      const hostUrl = window.location.host.split('.')
      return location.href.includes('localhost')
        ? 'localhost'
        : `.${hostUrl[1]}.${hostUrl[2]}`
    }
    if (customerAccessToken) {
      const data = await fetch<GetCustomerQuery, GetCustomerQueryVariables>({
        ...options,
        variables: { customerAccessToken, ordersFirst: 10 },
      })
      let obj = {
        id: data?.customer?.id,
        email: data?.customer?.email,
        orders_length: data?.customer?.orders?.edges?.filter(({ node }) => {
          return node?.lineItems?.edges?.some(({ node }) => {
            return node?.variant?.product?.tags?.some((t: any) =>
              ['charger_fall_pd_2309']?.includes(t)
            )
          })
        })?.length,
      }
      Cookies.set('userInfo', obj, {
        Domain: getDomain(),
        SameSite: 'Strict',
        Secure: true,
        expires: SHOPIFY_COOKIE_EXPIRE,
      })
      return data.customer
    } else if (multipassToken) {
      const token = await fetch<
        Mutation,
        MutationCustomerAccessTokenCreateWithMultipassArgs
      >({
        locale,
        query: customerAccessTokenCreateWithMultipass,
        variables: { multipassToken },
      })

      throwUserErrors(
        token.customerAccessTokenCreateWithMultipass?.customerUserErrors
      )

      const customerAccessToken =
        token.customerAccessTokenCreateWithMultipass?.customerAccessToken
      const accessToken = customerAccessToken?.accessToken
      if (accessToken) {
        setCustomerToken(accessToken, locale)
        // removeMultipassToken()
      }
      const data = await fetch<GetCustomerQuery, GetCustomerQueryVariables>({
        ...options,
        variables: { customerAccessToken: accessToken },
      })
      let obj = {
        id: data?.customer?.id,
        email: data?.customer?.email,
        orders_length: data?.customer?.orders?.edges?.filter(({ node }) => {
          return node?.lineItems?.edges?.some(({ node }) => {
            return node?.variant?.product?.tags?.some((t: any) =>
              ['charger_fall_pd_2309']?.includes(t)
            )
          })
        })?.length,
      }
      Cookies.set('userInfo', obj, {
        Domain: getDomain(),
        SameSite: 'Strict',
        Secure: true,
        expires: SHOPIFY_COOKIE_EXPIRE,
      })
      return data.customer
    }
    setCustomerToken(null, locale, {
      domain: window.location.hostname,
      sameSite: 'Strict',
      secure: true,
      expires: SHOPIFY_COOKIE_EXPIRE,
      path: '/',
    })
    Cookies.remove('userInfo', { domain: window.location.hostname, path: '/' })
    return null
  },
  useHook:
    ({ useData }) =>
    (input) => {
      return useData({
        swrOptions: {
          revalidateOnFocus: false,
          ...input?.swrOptions,
        },
      })
    },
}
