import useSWR, { SWRResponse } from 'swr'
import type {
  HookSWRInput,
  HookFetchInput,
  HookFetcherOptions,
  HookFetcherFn,
  Fetcher,
  SwrOptions,
  SWRHookSchemaBase,
} from './types'
import { useCommerce } from '..'
import defineProperty from './define-property'
import { CommerceError } from './errors'

export type ResponseState<Result> = SWRResponse<Result, CommerceError> & {
  isLoading: boolean
}

export type UseData = <H extends SWRHookSchemaBase>(
  options: {
    fetchOptions: HookFetcherOptions
    fetcher: HookFetcherFn<H>
  },
  input: HookFetchInput | HookSWRInput,
  fetcherFn: Fetcher,
  swrOptions?: SwrOptions<H['data'], H['fetcherInput']>
) => ResponseState<H['data']>

const useData: UseData = (options, input, fetcherFn, swrOptions) => {
  const hookInput = Array.isArray(input)
    ? input.reduce((obj: any, val: any) => {
        obj[val[0]] = val[1]
        return obj
      }, {})
    : input
  const { locale } = useCommerce()
  const fetcher = async ({ url, query, method, ...args }: any) => {
    try {
      return await options.fetcher({
        options: { url, query, method, locale },
        // Transform the input array into an object
        input: args,
        fetch: fetcherFn,
      })
    } catch (error) {
      // SWR will not log errors, but any error that's not an instance
      // of CommerceError is not welcomed by this hook
      if (!(error instanceof CommerceError)) {
        console.error(error)
      }
      throw error
    }
  }

  const response: any = useSWR(
    () => {
      const opts = options.fetchOptions
      return opts
        ? {
            url: opts.url,
            query: opts.query,
            method: opts.method,
            ...hookInput,
          }
        : null
    },
    fetcher,
    swrOptions
  )

  if (!('isLoading' in response)) {
    defineProperty(response, 'isLoading', {
      get() {
        return response.data === undefined
      },
      enumerable: true,
    })
  }

  return response as typeof response & { isLoading: boolean }
}

export default useData
