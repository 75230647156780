import { checkoutDetailsFragment } from '../queries/get-checkout-query'

const checkoutDiscountCodeApplyMutation = /* GraphQL */ `
  mutation checkoutDiscountCodeApplyV2(
    $checkoutId: ID!
    $discountCode: String!
  ) {
    checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...checkoutDetails
      }
    }
  }

  ${checkoutDetailsFragment}
`
export default checkoutDiscountCodeApplyMutation
