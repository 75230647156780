import type { Page } from '../types/page'
import type { Product } from '../types/product'
import type { Cart, LineItem } from '../types/cart'
import type {
  Category,
  CollectionsMetafield,
  FilterPriceTxt,
  FiltersPairMetafield,
  FiltersPair,
} from '../types/category'

import { formatPrice, languageTerritory } from '@commerce/product/use-price'
import { colorMap } from '@lib/colors'
import { ORIGIN_URL } from '@shopify/const'

import {
  Product as ShopifyProduct,
  ProductVariant,
  Checkout,
  CheckoutLineItemEdge,
  SelectedOption,
  ImageConnection,
  DiscountApplicationConnection,
  ProductVariantConnection,
  MoneyV2,
  ProductOption,
  Page as ShopifyPage,
  PageEdge,
  Collection,
  Maybe,
  Metafield,
  Article,
  Blog as ShopifyBlog,
  BlogEdge,
} from '../schema'

const money = ({ amount, currencyCode }: MoneyV2) => {
  return (
    amount &&
    currencyCode && {
      value: +amount,
      currencyCode,
    }
  )
}

const availableVariant = (variant: ProductVariant) => {
  //availableForSale 是否可售 true:可售
  if (!variant.availableForSale) return false
  //currentlyNotInStock 是否可超卖 true:是
  //quantityAvailable 库存
  if (!variant.quantityAvailable && !variant.currentlyNotInStock) return false
  if (variant.price >= 9999999.99) return false
  return true
}

const normalizeProductOption = ({
  id,
  name: displayName,
  values,
}: ProductOption) => {
  return {
    __typename: 'MultipleChoiceOption',
    id,
    displayName: displayName.toLowerCase(),
    name: displayName,
    values: values.map((value) => {
      let output: any = {
        label: value,
      }
      if (displayName.match(/colou?r/gi)) {
        const mapedColor = colorMap[value.toLowerCase().replace(/ /g, '')]
        if (mapedColor) {
          output = {
            ...output,
            hexColors: [mapedColor],
          }
        }
      }
      return output
    }),
  }
}

const normalizeDiscounts = ({ edges }: DiscountApplicationConnection) =>
  edges?.map(({ node: { value } }) => ({
    ...value,
  }))

const normalizeProductImages = ({ edges }: ImageConnection) =>
  edges?.map(({ node: { originalSrc, url, ...rest } }) => ({
    url: originalSrc || url,
    ...rest,
  }))

const normalizeProductVariants = (
  { edges }: ProductVariantConnection,
  previewData?: any
) => {
  return edges?.map(({ node }) => {
    const {
      id,
      selectedOptions,
      sku,
      title,
      priceV2,
      compareAtPriceV2,
      requiresShipping,
      quantityAvailable,
      currentlyNotInStock,
      image,
      ...rest
    } = node
    const price = +priceV2.amount
    const metafields = normalizeMetafields(
      rest,
      previewData,
      'variant'
    ).metafields
    let variantTags = null
    try {
      variantTags = metafields?.variantTags
        ? JSON.parse(metafields?.variantTags)
        : null
    } catch (error) {
      console.error('variantTags parse error:', error)
    }
    return {
      ...rest,
      id,
      name: title,
      sku: sku ?? id,
      price,
      image,
      listPrice: +compareAtPriceV2?.amount,
      requiresShipping,
      quantityAvailable,
      currentlyNotInStock,
      availableForSale: availableVariant({ ...node, price }),
      options: selectedOptions.map(({ name, value }: SelectedOption) => {
        const options = normalizeProductOption({
          id,
          name,
          values: [value],
        })
        return options
      }),
      metafields,
      ...(variantTags && { tags: variantTags }),
    }
  })
}

export function normalizeProduct(
  {
    id,
    title,
    vendor,
    images,
    variants,
    description,
    descriptionHtml,
    handle,
    priceRange,
    compareAtPriceRange,
    options,
    ...rest
  }: ShopifyProduct,
  previewData?: any
): Product {
  const customVariants = variants
    ? normalizeProductVariants(variants, previewData)
    : []
  return {
    ...rest,
    ...(description && { description }),
    ...(descriptionHtml && { descriptionHtml }),
    id,
    name: title,
    vendor,
    handle,
    title,
    path: `/${handle}`,
    slug: handle?.replace(/^\/+|\/+$/g, ''),
    price: money(priceRange?.minVariantPrice),
    listPrice: money(compareAtPriceRange?.minVariantPrice)?.value,
    images: normalizeProductImages(images),
    variants: customVariants,
    availableForSale:
      customVariants.length > 0
        ? customVariants.some((item) => item?.availableForSale)
        : rest?.availableForSale,
    options: options
      ? options
          .filter((o) => o.name !== 'Title') // By default Shopify adds a 'Title' name when there's only one option. We don't need it. https://community.shopify.com/c/Shopify-APIs-SDKs/Adding-new-product-variant-is-automatically-adding-quot-Default/td-p/358095
          .map((o) => normalizeProductOption(o))
      : [],
    metafields: normalizeMetafields(rest, previewData, 'product').metafields,
  }
}

export function normalizeCart(checkout: Checkout): Cart {
  return {
    id: checkout.id,
    url: checkout.webUrl,
    ready: checkout.ready,
    orderStatusUrl: checkout.orderStatusUrl,
    customerId: '',
    email: '',
    createdAt: checkout.createdAt,
    currency: {
      code: checkout.totalPriceV2?.currencyCode,
    },
    taxesIncluded: checkout.taxesIncluded,
    lineItems: checkout.lineItems?.edges.map(normalizeLineItem),
    lineItemsSubtotalPrice: checkout.lineItemsSubtotalPrice?.amount,
    subtotalPrice: checkout.subtotalPriceV2?.amount,
    totalPrice: checkout.totalPriceV2?.amount,
    discounts: normalizeDiscounts(checkout.discountApplications),
  }
}

function normalizeLineItem({
  node: { id, title, variant, quantity, discountAllocations, customAttributes },
}: CheckoutLineItemEdge): LineItem {
  const price = variant?.priceV2?.amount
  return {
    id,
    variantId: String(variant?.id),
    productId: String(variant?.id),
    name: `${title}`,
    quantity,
    discountAllocations: discountAllocations,
    customAttributes: customAttributes,
    variant: {
      id: String(variant?.id),
      price,
      listPrice: variant?.compareAtPriceV2?.amount,
      sku: variant?.sku ?? '',
      name: variant?.title!,
      image: {
        url: variant?.image?.originalSrc || '/product-img-placeholder.svg',
      },
      requiresShipping: variant?.requiresShipping ?? false,
      availableForSale: availableVariant({
        ...variant,
        price,
      } as ProductVariant),
      quantityAvailable: variant?.quantityAvailable || 0,
      currentlyNotInStock: variant?.currentlyNotInStock || false,
      metafields: normalizeMetafields(variant || {})?.metafields,
    },
    product: variant?.product,
    path: `/${variant?.product?.handle}`,
    discounts: [],
    options: variant?.title == 'Default Title' ? [] : variant?.selectedOptions,
  }
}

export const normalizeComponentMetafields = (
  { variants, ...rest }: any,
  previewData?: any,
  resource_type?: string
) => {
  let variantsMetafields = {}
  if (variants?.edges) {
    variantsMetafields = variants.edges.map(({ node }: any) => {
      const { id, title, sku, ...rest } = node
      return {
        id,
        title,
        sku,
        ...normalizeMetafields(rest, previewData, resource_type),
      }
    })
    return {
      ...normalizeMetafields(rest, previewData, resource_type).metafields,
      variantsMetafields,
    }
  }
  return { ...normalizeMetafields(rest, previewData, resource_type).metafields }
}

export const normalizePage = (
  { title, handle, onlineStoreUrl, ...page }: ShopifyPage,
  previewData?: any,
  resource_type?: any
): Page => {
  const ref = normalizeMetafields(page, previewData, 'page')
  return {
    ...ref,
    path: ref?.metafields?.path || `/${handle}`,
    name: title,
    title,
    onlineStoreUrl: onlineStoreUrl || null,
    handle,
  }
}

export const normalizePages = (edges: PageEdge[], previewData?: any): Page[] =>
  edges?.map((edge) => normalizePage(edge.node, previewData, 'page'))

export const normalizeBlog = (
  blog: ShopifyBlog,
  previewData?: any,
  resource_type?: any
) => {
  return {
    ...blog,
    ...(blog?.articles && {
      articles: blog?.articles.edges.map(({ node }) =>
        normalizeArticle(node as Article, previewData)
      ),
    }),
    article: blog?.article ? normalizeArticle(blog?.article) : {},
    metafields: normalizeMetafields(blog)?.metafields,
  }
}

export const normalizeArticle = (
  { seo, ...article }: Article,
  previewData?: any,
  resource_type?: any
) => {
  return {
    ...article,
    seo: seo || {},
    excerpt: article?.excerpt || seo?.description || '',
    metafields: normalizeMetafields(article)?.metafields,
  }
}

export const normalizeCategory = (
  { title: name, handle, id, products, ...category }: Collection,
  previewData?: any
): Category => {
  return {
    id,
    name,
    seo: category?.seo || {},
    description: category?.description,
    slug: handle,
    image: category?.image,
    path: `/collections/${handle}`,
    pageInfo: products?.pageInfo || {},
    filters: products?.filters || [],
    ...(products && {
      products: products.edges.map(({ node }) =>
        normalizeProduct(node as ShopifyProduct, previewData)
      ),
    }),
    metafields: normalizeMetafields(category, previewData, 'collection')
      ?.metafields,
  }
}

export const normalizeMetafields = (
  obj: Maybe<{ [key: string]: any }>,
  previewData?: any,
  resource_type?: string
) => {
  if (!obj) return { metafields: {} }
  const others: any = {}
  const metafields: { [key: string]: string } = {}
  Object.keys(obj).forEach((key: string) => {
    if (/^MF_/.test(key)) {
      metafields[key.substr(3)] = parseMetafield(
        obj[key],
        previewData,
        resource_type
      )
    } else {
      others[key] = obj[key]
    }
  })

  return { ...others, metafields }
}

export function parseJSON(str: string) {
  try {
    return JSON.parse(str)
  } catch (err) {
    return {}
  }
}

const parseDesc = (desc: string) => {
  const cfg = parseJSON(desc) || {}
  return {
    parseType: cfg.type, // JSON_STRING
    descContent: cfg.content || '', // 描述
    status: cfg.status || 'Active', // 状态
  }
}

export const parseMetafield = (
  item: Metafield,
  previewData?: any,
  resource_type?: string
) => {
  const type = item?.type && item?.type.toLowerCase()
  const { status } = parseDesc(item?.description || '')
  if (status.toLowerCase() !== 'active') return null
  if (
    item &&
    previewData &&
    previewData?.__preview_type === resource_type &&
    previewData[`${item?.namespace}${item?.key}`] !== undefined &&
    previewData[`${item?.namespace}${item?.key}`] !== null
  ) {
    return previewData[`${item.namespace}${item.key}`]
  }
  switch (type) {
    case 'json':
    case 'json_string':
    case 'rating':
    case 'volume':
    case 'weight':
    case 'dimension':
      return JSON.parse(item.value)
    default:
      return item?.value || item
  }
}

export function atobID(id: string) {
  if (!id) {
    return null
  }
  return id.split('/').pop()?.split('?')?.shift()
  // const value = id.toString()
  // if (/[a-zA-Z]/i.test(value)) {
  //   let gid;
  //   if (isBrowser()) {
  //     gid = window.atob(value).split('/')
  //   } else {
  //     gid = Buffer.from(value, 'base64').toString('ascii').split('/')
  //   }
  // }
  // return value
}

export function btoaID(id: string, type = 'ProductVariant') {
  return `gid://shopify/${type}/${id}`
}

const normalizePriceOption = ({
  option,
  price_label,
  currencyCode = 'us',
  locale,
}: {
  option: number[]
  price_label: FilterPriceTxt
  currencyCode?: string
  locale: string
}) => {
  return option
    .map((val) => {
      if (val <= 0) return price_label.lte
      if (val >= 99999) return price_label.gte
      const value = formatPrice({
        locale,
        amount: val,
        currencyCode,
      })
      return value
    })
    .join(option[0] > 0 && option[1] < 99999 ? ' - ' : ' ')
}

export const normalizeFilterPrice = ({
  filters_pair,
  products,
  locale = 'us',
}: {
  filters_pair: FiltersPairMetafield
  locale?: string
  products?: Product[]
}) => {
  const { collections, filters_label, price: price_label } = filters_pair
  const currencyCode = products?.[0]?.price?.currencyCode
  let fp = 'price'
  let min: number = 0
  let max: number = 0
  products?.forEach((item: Product) => {
    if (!min && item.price.value < 999999) min = Number(item.price.value)
    if (!max && item.price.value < 999999) max = Number(item.price.value)
    if (min > item.price.value && item.price.value < 999999)
      min = item.price.value
    if (max < item.price.value && item.price.value < 999999)
      max = item.price.value
  })
  const poptions = divideIntoFourEqualParts(min, max) || []
  return {
    label: filters_label[fp] || '',
    value: fp,
    options: poptions?.map((option) => ({
      value: option.join('|'),
      label: normalizePriceOption({
        option,
        price_label,
        currencyCode,
        locale,
      }),
    })),
  }
}

const divideIntoFourEqualParts = (minValue: number, maxValue: number) => {
  // 计算区间的长度
  const range = maxValue - minValue

  // 计算每份的长度
  const segmentLength = range / 4

  // 创建一个存储区间的数组
  const segments = []

  // 循环生成4个区间
  for (let i = 0; i < 4; i++) {
    // 计算区间的最小值和最大值
    let segmentMin = 0
    let segmentMax = 100
    if (i === 0) {
      segmentMin = Math.ceil(minValue + i * segmentLength - 1)
    } else {
      segmentMin = Math.ceil(minValue + i * segmentLength)
    }
    if (i === 3) {
      segmentMax = Math.ceil(minValue + (i + 1) * segmentLength + 1) - 0.01
    } else {
      segmentMax = Math.ceil(minValue + (i + 1) * segmentLength) - 0.01
    }

    // 存储区间
    segments.push([Math.round(segmentMin), Math.round(segmentMax * 100) / 100])
  }

  return segments
}

export const getFirstCollection = (
  allCollections?: CollectionsMetafield,
  handle?: string
) => {
  if (!allCollections || !handle) return false
  if (allCollections[handle]) return handle
  return (
    Object.keys(allCollections).find(
      (key: string) => allCollections[key].indexOf(handle) > -1
    ) || false
  )
}

export const normalizeFilters = ({
  filters_pair,
  allCollections,
  products,
  handle,
  locale,
}: {
  filters_pair: FiltersPairMetafield
  allCollections: CollectionsMetafield
  handle: string
  locale?: string
  products?: Product[]
}) => {
  const { collections, filters_label, price: price_label } = filters_pair
  const all_filters = Object.keys(filters_label)
  const main = getFirstCollection(allCollections, handle)
  if (!main || !locale || !products || !products?.length || !collections[main])
    return []
  const { filter_params, price: price_option } = collections[main]

  if (all_filters && filters_label) {
    const currencyCode = products?.[0]?.price?.currencyCode
    const filters = all_filters
      .map((fp: string) => {
        if (fp === 'price') {
          return {
            label: filters_label[fp] || '',
            value: fp,
            options: price_option?.map((option) => ({
              value: option.join('|'),
              label: normalizePriceOption({
                option,
                price_label,
                currencyCode,
                locale,
              }),
            })),
          }
        }
        const filter: FiltersPair = {
          label: filters_label[fp] || '',
          value: fp,
          options: [],
        }
        if (fp === 'product_type') {
          const hasActive: string[] = []
          products.forEach((item: Product) => {
            if (
              item.productType &&
              !hasActive.find((op) => op === item.productType)
            ) {
              hasActive.push(item.productType || '')
            }
          })
          filter.options = hasActive.sort().map((val) => ({
            value: val,
            label: val,
          }))
        } else {
          const fp_labels = filters_pair[fp] || {}
          const hasActive: string[] = []
          products.forEach((item: Product) => {
            item.variants.forEach((variant) => {
              const fm = variant[`filter_${fp}`]
              if (fp_labels && fm) {
                const value = fm.value
                hasActive.find((op) => op === value) || hasActive.push(value)
              }
            })
          })
          filter.options = Object.keys(fp_labels)
            .filter((key) => hasActive.find((op) => op === key))
            .map((key) => ({
              value: key,
              label: fp_labels[key],
            }))
        }
        return filter
      })
      .filter((item) => item && item?.options && item?.options?.length > 0)
    return filters
  }
  return false
}

export const normalizeSearchResult = (data: any) => {
  return {
    pageInfo: data.search.pageInfo,
    productFilters: data.search.productFilters,
    totalCount: data.search.totalCount,
    items: data.search.edges
      .map((edge: any) => {
        switch (edge.node.__typename) {
          case 'Product':
            return normalizeProduct(edge.node)
          case 'Page':
            return normalizePage(edge.node)
          case 'Article':
            return normalizeArticle(edge.node)
          default:
            break
        }
      })
      .map((item: any) => ({ ...item, type: item.__typename.toLowerCase() })),
  }
}

export const normalizeHrefLang = ({
  hrefLangInfos,
  locale,
  locales,
  slug,
}: {
  hrefLangInfos: {
    [key: string]: string
  }
  locales: string[]
  locale: string
  slug: string
}) => {
  const hrefLangResult = []
  if (hrefLangInfos && Object.keys(hrefLangInfos)?.length > 0) {
    hrefLangResult.push({
      hrefLang: languageTerritory(locale || 'us'),
      href: `${ORIGIN_URL}${locale === 'us' ? '' : '/' + locale}${slug}`,
    })
    for (const lang in hrefLangInfos) {
      hrefLangInfos[lang] &&
        locales.includes(lang) &&
        hrefLangResult.push({
          hrefLang: languageTerritory(lang || 'us'),
          href: `${ORIGIN_URL}${lang === 'us' ? '' : '/' + lang}${
            hrefLangInfos[lang]
          }`,
        })
    }
    if (hrefLangResult.length > 0) {
      hrefLangResult.push({
        hrefLang: 'x-default',
        href: hrefLangResult[0].href,
      })
    }

    return hrefLangResult
  } else {
    return null
  }
}

export const normalizeConfCollection = (
  metafields: any,
  categories: Array<Category>
) => {
  const pageType = metafields?.pageType?.pageType || 'charger'
  const menuPps = metafields?.menuPps || {}
  const menuCharger = metafields?.menuCharger || {}
  const shop_collections = metafields?.shop_collections || {}
  const navData =
    pageType === 'powerStation' || pageType === 'hes' ? menuPps : menuCharger
  const dropDownContent = navData?.dropDownContent || []

  const collectionList: Array<string> = []

  dropDownContent?.forEach((item: any) => {
    if (['collections', 'newCollections']?.includes(item?.mode)) {
      if (item?.mode === 'collections') {
        Array.isArray(item?.content) &&
          item?.content?.forEach((collection: any) => {
            if (collection?.handle) {
              collectionList.push(collection?.handle)
              if (Array.isArray(shop_collections?.[collection?.handle])) {
                collectionList?.push(...shop_collections?.[collection?.handle])
              }
            }
          })
      } else {
        Array.isArray(item?.content) &&
          item?.content?.forEach((collection: any) => {
            if (collection?.handle) {
              collectionList.push(collection?.handle)
              if (Array.isArray(shop_collections?.[collection?.handle])) {
                collectionList?.push(...shop_collections?.[collection?.handle])
              }
            }
            if (Array.isArray(collection?.collectionList)) {
              collection?.collectionList?.forEach((subCollection: any) => {
                if (subCollection?.handle) {
                  collectionList.push(subCollection?.handle)
                  if (
                    Array.isArray(shop_collections?.[subCollection?.handle])
                  ) {
                    collectionList?.push(
                      ...shop_collections?.[subCollection?.handle]
                    )
                  }
                }
              })
            }
            // 配置自定义时的子品类
            if (Array.isArray(collection?.childCollectionList)) {
              collection?.childCollectionList?.forEach((subCollection: any) => {
                if (subCollection?.handle) {
                  collectionList.push(subCollection?.handle)
                  if (
                    Array.isArray(shop_collections?.[subCollection?.handle])
                  ) {
                    collectionList?.push(
                      ...shop_collections?.[subCollection?.handle]
                    )
                  }
                }
              })
            }
          })
      }
    }
  })

  return categories?.filter((category: any) =>
    collectionList.includes(category?.slug)
  )
}
