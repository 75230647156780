import { SWRHook } from '@commerce/utils/types'
import useSearch, { UseSearch } from '@commerce/product/use-search'

import { searchQuery, normalizeSearchResult } from '../utils'

import type { SearchHook } from '@commerce/types/search'

export type SearchProductsInput = {
  after?: string
  before?: string
  sortKey?: string
  locale?: string
  first?: number
  last?: number
  types?: string
  productFilters?: any
  reverse?: boolean
  searchTerm?: string
}

export default useSearch as UseSearch<typeof handler>

export const validSortKey = ['PRICE', 'RELEVANCE']

export const handler: SWRHook<SearchHook> = {
  fetchOptions: {
    query: searchQuery,
  },

  async fetcher({ input, options, fetch }) {
    const {
      searchTerm,
      first,
      sortKey,
      productFilters,
      types,
      reverse,
      after,
      before,
    } = input
    const method = options?.method

    const data = await fetch({
      query: options.query,
      locale: options.locale,
      method,
      variables: {
        first: first || 8,
        sortKey: sortKey || 'RELEVANCE',
        productFilters,
        query: searchTerm,
        types: types?.split(',') || ['ARTICLE', 'PRODUCT'],
        reverse,
        after,
        before,
      },
    })
    return normalizeSearchResult(data)
  },

  useHook:
    ({ useData }) =>
    (input = {}) => {
      return useData({
        input: [
          ['productFilters', input.productFilters],
          ['searchTerm', input.searchTerm],
          ['sortKey', input.sortKey],
          ['locale', input.locale],
          ['first', input.first],
          ['after', input.after],
          ['before', input.before],
          ['last', input.last],
          ['types', input.types],
          ['reverse', input.reverse],
        ],
        swrOptions: {
          revalidateOnFocus: false,
          ...input.swrOptions,
        },
      })
    },
}
