import { useCallback } from 'react'
import type { MutationHook } from '@commerce/utils/types'
import { CommerceError } from '@commerce/utils/errors'
import useRecover, { UseRecover } from '@commerce/auth/use-recover'
import type { RecoverHook } from '../types/recover'
import useCustomer from '../customer/use-customer'
import { Mutation, MutationCustomerCreateArgs } from '../schema'

import { throwUserErrors, customerRecoverMutation } from '../utils'

export default useRecover as UseRecover<typeof handler>

export const handler: MutationHook<RecoverHook> = {
  fetchOptions: {
    query: customerRecoverMutation,
  },
  async fetcher({ input: { email }, options, fetch }) {
    if (!email) {
      throw new CommerceError({
        message: 'A email are required to signup',
      })
    }

    const { customerRecover } = await fetch<
      Mutation,
      MutationCustomerCreateArgs
    >({
      ...options,
      variables: { email },
    })

    throwUserErrors(customerRecover?.customerUserErrors)

    return null
  },
  useHook:
    ({ fetch }) =>
    () => {
      const { mutate } = useCustomer()

      return useCallback(
        async function login(input) {
          const data = await fetch({ input })
          await mutate(data, false)
          return data
        },
        [mutate]
      )
    },
}
