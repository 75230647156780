import { checkoutDetailsFragment } from '../queries/get-checkout-query'

const checkoutDiscountCodeRemoveMutation = /* GraphQL */ `
  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
    checkoutDiscountCodeRemove(
      checkoutId: $checkoutId
    ) {
      checkoutUserErrors {
        code
        field
        message
      }
      checkout {
        ...checkoutDetails
      }
    }
  }
  ${checkoutDetailsFragment}
`
export default checkoutDiscountCodeRemoveMutation
