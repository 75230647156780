import { useCallback } from 'react'
import debounce from 'lodash.debounce'
import type {
  HookFetcherContext,
  MutationHookContext,
} from '@commerce/utils/types'
import { ValidationError, CommerceError } from '@commerce/utils/errors'
import useUpdateItem, { UseUpdateItem } from '@commerce/cart/use-update-item'
import { useCommerce } from '@commerce/index'
import useCart from './use-cart'
import { handler as removeItemHandler } from './use-remove-item'
import type { UpdateItemHook, LineItem } from '../types/cart'
import {
  getCheckoutId,
  checkoutLineItemUpdateMutation,
  checkoutToCart,
} from '../utils'
import { Mutation, MutationCheckoutLineItemsUpdateArgs } from '../schema'

export type UpdateItemActionInput<T = any> = T extends LineItem
  ? Partial<UpdateItemHook['actionInput']>
  : UpdateItemHook['actionInput']

export default useUpdateItem as UseUpdateItem<typeof handler>

export const handler = {
  fetchOptions: {
    query: checkoutLineItemUpdateMutation,
  },
  async fetcher({
    input: { itemId, item },
    options,
    fetch,
  }: HookFetcherContext<UpdateItemHook>) {
    if (Number.isInteger(item.quantity)) {
      // Also allow the update hook to remove an item if the quantity is lower than 1
      if (item.quantity! < 1) {
        return removeItemHandler.fetcher({
          options: removeItemHandler.fetchOptions,
          input: { itemId },
          fetch,
        })
      }
    } else if (item.quantity) {
      throw new ValidationError({
        message: 'The item quantity has to be a valid integer',
      })
    }

    const { locale } = options
    const { checkoutLineItemsUpdate } = await fetch<
      Mutation,
      MutationCheckoutLineItemsUpdateArgs
    >({
      ...options,
      variables: {
        checkoutId: getCheckoutId(locale),
        lineItems: [
          {
            id: itemId,
            quantity: item.quantity,
            customAttributes: item?.customAttributes || [],
          },
        ],
      },
    })

    return checkoutToCart(checkoutLineItemsUpdate, locale)
  },
  useHook:
    ({ fetch }: MutationHookContext<UpdateItemHook>) =>
    <T extends LineItem | undefined = undefined>(ctx?: {
      item?: T
      wait?: number
    }) => {
      const { item, wait } = ctx || {}
      const { mutate, data } = useCart() as any
      const { shop } = useCommerce()
      const orderLimits = shop?.orderLimits || {}
      return useCallback(
        async (input: UpdateItemActionInput<T>) => {
          let result = true
          const itemId = input.id ?? item?.id
          const productId = input.productId ?? item?.productId
          const variantId = input.productId ?? item?.variantId
          if (!itemId || !productId || !variantId) {
            throw new ValidationError({
              message: 'Invalid input used for this operation',
            })
          }
          const errors: Array<string> = []
          //shopify会对同一个商品不同活动时做拆分，这里需要汇总同一个sku的库存数
          //这里是修改所以汇总时一定要排除当前Item
          const lines: Record<string, any> = {}
          data?.lineItems?.forEach((line: any) => {
            if (line.id !== itemId) {
              lines[line.variantId] = lines[line.variantId] || {
                quantity: 0,
                variant: {
                  currentlyNotInStock: line?.variant?.currentlyNotInStock,
                  quantityAvailable: line?.variant?.quantityAvailable,
                  sku: line?.variant?.sku,
                },
                options: line.options,
                name: line?.name,
              }
              lines[line.variantId].quantity =
                lines[line.variantId].quantity + line.quantity
            }
          })
          if (lines?.[`${variantId}`]?.quantity) {
            const variant = {
              ...(item?.variant || {
                currentlyNotInStock: false,
                quantityAvailable: 0,
                sku: '',
              }),
            }
            //库存,如果允许超卖(允许超卖的商品也有9999限制,9999数可配置)
            const available = variant?.currentlyNotInStock
              ? orderLimits?.quantityMax || 9999
              : variant.quantityAvailable
            //根据配置判断最大加购
            const orderLimit = orderLimits?.limits?.[variant?.sku]
            const limitMax = orderLimit?.max
            const max =
              limitMax !== undefined && limitMax <= available
                ? limitMax
                : available
            const sumQuantity =
              lines?.[`${variantId}`]?.quantity + input.quantity
            if (sumQuantity > max) {
              result = false
              let tips =
                orderLimits?.tips?.updateErrorTip ||
                `Must have at most {max} of this item.`
              const options = item?.options?.map((option: any) => {
                return `${option?.name}:${option?.value}`
              })
              tips = tips
                ?.replace('{name}', item?.name)
                ?.replace('{options}', options?.join(',') || item?.name)
                ?.replace('{max}', max)
              errors.push(tips)
            }
          }
          if (result) {
            const debounceUpdateItem = debounce(async () => {
              const resultData = await fetch({
                input: {
                  item: {
                    productId,
                    variantId,
                    quantity: input.quantity,
                    customAttributes: input?.customAttributes,
                  },
                  itemId,
                },
              })
              await mutate(resultData, false)
              return data
            }, wait ?? 500)
            return debounceUpdateItem()
          } else {
            throw new CommerceError({
              message: `${errors?.join(',')}`,
              code: 'orderLimit',
            })
          }
        },
        [fetch, mutate, data]
      ) as any
    },
}
