import React, { FC, useCallback, useMemo } from 'react'
import { ThemeProvider } from 'next-themes'
import { pageGTMEvent } from '@lib/utils/thirdparty'

export enum LoginPopTab {
  Register = 'register',
  Login = 'login',
}
export interface State {
  combinationOpt: Array<string>
  combinationImg: Array<string>
  displaySidebar: boolean
  displayDropdown: boolean
  displayModal: boolean
  cartAction: Function | null
  sidebarView: string
  productSwiperIndex: number
  modalView: string
  userAvatar: string
  showBundlePopup: boolean
  recaptchaReady: boolean
  gtmReady: boolean
  getSalePrice: number
  getOriginalPrice: number
  menuTheme: boolean | string
  tipModal: string
  pageType: string
  previewData: any
  couponInListing: any
  contextTradeGift: any
  selectedOptions: any
  combineOption: any
  combinePrice: any
  combineVariant: any
  inViewDom: any
  loginPop: boolean
  loginDefaultTab: LoginPopTab
  countryModal: any
  showRegistrationsPopup: boolean
  registrationsModelStatus: string
  customRedirectUrl: string
  fbqTrack: string
}

const initialState = {
  previewData: {},
  couponInListing: {},
  contextTradeGift: null,
  selectedOptions: {},
  combineOption: {},
  combinePrice: {},
  combineVariant: [],
  inViewDom: {},
  combinationOpt: [],
  combinationImg: [],
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  cartAction: null,
  modalView: 'LOGIN_VIEW',
  sidebarView: 'CART_VIEW',
  productSwiperIndex: 0,
  userAvatar: '',
  recaptchaReady: false,
  gtmReady: false,
  getSalePrice: 0,
  getOriginalPrice: 0,
  fireworkReady: false,
  tipModal: '',
  pageType: '',
  showBundlePopup: false,
  menuTheme: false,
  isQuiz: false,
  isQuizDialog: false,
  isSignupDialog: false,
  loginPop: false,
  loginDefaultTab: LoginPopTab.Register,
  countryModal: {
    visible: false,
    position: 'bottom',
  },
  showRegistrationsPopup: false,
  registrationsModelStatus: '',
  customRedirectUrl: '',
  fbqTrack: '',
}

type Action =
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'OPEN_DROPDOWN'
    }
  | {
      type: 'CLOSE_DROPDOWN'
    }
  | {
      type: 'OPEN_MODAL'
    }
  | {
      type: 'CLOSE_MODAL'
    }
  | {
      type: 'SET_MODAL_VIEW'
      view: MODAL_VIEWS
    }
  | {
      type: 'SET_SWIPER_INDEX'
      view: number
    }
  | {
      type: 'SET_SIDEBAR_VIEW'
      view: SIDEBAR_VIEWS
    }
  | {
      type: 'SET_COMBINATION'
      value: Array<string>
    }
  | {
      type: 'SET_COMBINATION_IMG'
      value: Array<string>
    }
  | {
      type: 'SET_USER_AVATAR'
      value: string
    }
  | {
      type: 'SET_RECAPTCHA'
      value: boolean
    }
  | {
      type: 'SET_CARTACTION'
      value: Function | null
    }
  | {
      type: 'SET_GTM'
      value: boolean
    }
  | {
      type: 'SET_FIREWORK'
      value: boolean
    }
  | {
      type: 'SET_TIP_MODAL'
      value: string
    }
  | {
      type: 'SET_PAGETYPE'
      value: string
    }
  | {
      type: 'SET_BUNDLE_POPUP'
      value: boolean
    }
  | {
      type: 'SET_MENU_THEME'
      value: boolean
    }
  | {
      type: 'SET_IS_QUIZ'
      value: boolean
    }
  | {
      type: 'SET_IS_QUIZ_DIALOG'
      value: boolean
    }
  | {
      type: 'SET_IS_SIGNUP_DIALOG'
      value: boolean
    }
  | {
      type: 'SET_PREVIEW_DATA'
      value: any
    }
  | {
      type: 'SET_INVIEWDOM_DATA'
      value: any
    }
  | {
      type: 'SET_COUPONLISTING_DATA'
      value: any
    }
  | {
      type: 'SET_TRADEGIFT_DATA'
      value: any
    }
  | {
      type: 'SET_OPTIONS_DATA'
      value: any
    }
  | {
      type: 'SET_COMBINEOPTION_DATA'
      value: any
    }
  | {
      type: 'SET_COMBINEPRICE_DATA'
      value: any
    }
  | {
      type: 'SET_COMBINEVARIANT_DATA'
      value: any
    }
  | {
      type: 'SET_SALEPRICE'
      value: number
    }
  | {
      type: 'SET_ORIGINALPRICE'
      value: number
    }
  | {
      type: 'SET_LOGIN_POP'
      value: boolean
    }
  | {
      type: 'SET_LOGIN_DEFAULT_TAB'
      value: string
    }
  | {
      type: 'SET_COUNTRYMODAL'
      value: any
    }
  | {
      type: 'SET_REGISTRATIONS_POPUP'
      value: boolean
    }
  | {
      type: 'SET_REGISTRATIONS_MODEL_STATUS'
      value: string
    }
  | {
      type: 'SET_REGISTRATIONS_CUSTOM_REDIRECT_URL'
      value: string
    }
  | {
      type: 'SET_REGISTRATIONS_FBQ_TRACK'
      value: string
    }

type MODAL_VIEWS =
  | 'SIGNUP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGOT_VIEW'
  | 'NEW_SHIPPING_ADDRESS'
  | 'NEW_PAYMENT_METHOD'

type SIDEBAR_VIEWS = 'CART_VIEW' | 'CHECKOUT_VIEW' | 'PAYMENT_METHOD_VIEW'

export const UIContext = React.createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
      }
    }
    case 'SET_MODAL_VIEW': {
      return {
        ...state,
        modalView: action.view,
      }
    }
    case 'SET_CARTACTION': {
      return {
        ...state,
        cartAction: action.value,
      }
    }
    case 'SET_SIDEBAR_VIEW': {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case 'SET_SWIPER_INDEX': {
      return {
        ...state,
        productSwiperIndex: action.view,
      }
    }
    case 'SET_COMBINATION': {
      return {
        ...state,
        combinationOpt: action.value,
      }
    }
    case 'SET_COMBINATION_IMG': {
      return {
        ...state,
        combinationImg: action.value,
      }
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case 'SET_RECAPTCHA': {
      return {
        ...state,
        recaptchaReady: action.value,
      }
    }
    case 'SET_SALEPRICE': {
      return {
        ...state,
        getSalePrice: action.value,
      }
    }
    case 'SET_ORIGINALPRICE': {
      return {
        ...state,
        getOriginalPrice: action.value,
      }
    }
    case 'SET_GTM': {
      return {
        ...state,
        gtmReady: action.value,
      }
    }
    case 'SET_FIREWORK': {
      return {
        ...state,
        fireworkReady: action.value,
      }
    }
    case 'SET_TIP_MODAL': {
      return {
        ...state,
        tipModal: action.value,
      }
    }
    case 'SET_PAGETYPE': {
      return {
        ...state,
        pageType: action.value,
      }
    }
    case 'SET_BUNDLE_POPUP': {
      return {
        ...state,
        showBundlePopup: action.value,
      }
    }
    case 'SET_MENU_THEME': {
      return {
        ...state,
        menuTheme: action.value,
      }
    }
    case 'SET_IS_QUIZ': {
      return {
        ...state,
        isQuiz: action.value,
        isQuizDialog: action.value,
      }
    }
    case 'SET_IS_QUIZ_DIALOG': {
      return {
        ...state,
        isQuizDialog: action.value,
      }
    }
    case 'SET_IS_SIGNUP_DIALOG': {
      return {
        ...state,
        isSignupDialog: action.value,
      }
    }
    case 'SET_PREVIEW_DATA': {
      return {
        ...state,
        previewData: action.value,
      }
    }
    case 'SET_COUPONLISTING_DATA': {
      return {
        ...state,
        couponInListing: action.value,
      }
    }
    case 'SET_TRADEGIFT_DATA': {
      return {
        ...state,
        contextTradeGift: action.value,
      }
    }
    case 'SET_OPTIONS_DATA': {
      return {
        ...state,
        selectedOptions: action.value,
      }
    }
    case 'SET_COMBINEOPTION_DATA': {
      return {
        ...state,
        combineOption: action.value,
      }
    }
    case 'SET_COMBINEPRICE_DATA': {
      return {
        ...state,
        combinePrice: action.value,
      }
    }
    case 'SET_COMBINEVARIANT_DATA': {
      return {
        ...state,
        combineVariant: action.value,
      }
    }
    case 'SET_INVIEWDOM_DATA': {
      return {
        ...state,
        inViewDom: action.value,
      }
    }
    case 'SET_LOGIN_POP': {
      return {
        ...state,
        previewData: action.value,
        loginPop: action.value,
      }
    }
    case 'SET_LOGIN_DEFAULT_TAB': {
      return {
        ...state,
        previewData: action.value,
        loginDefaultTab: action.value as LoginPopTab,
      }
    }
    case 'SET_COUNTRYMODAL': {
      return {
        ...state,
        countryModal: action.value,
      }
    }
    case 'SET_REGISTRATIONS_POPUP': {
      return {
        ...state,
        showRegistrationsPopup: action.value,
      }
    }
    case 'SET_REGISTRATIONS_MODEL_STATUS': {
      return {
        ...state,
        registrationsModelStatus: action.value,
      }
    }
    case 'SET_REGISTRATIONS_CUSTOM_REDIRECT_URL': {
      return {
        ...state,
        customRedirectUrl: action.value,
      }
    }
    case 'SET_REGISTRATIONS_FBQ_TRACK': {
      return {
        ...state,
        fbqTrack: action.value,
      }
    }
  }
}

export const UIProvider: FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState)

  const openSidebar = useCallback(() => {
    pageGTMEvent({
      event: 'cart_recommend',
    })
    return dispatch({ type: 'OPEN_SIDEBAR' })
  }, [dispatch])
  const closeSidebar = useCallback(
    () => dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch]
  )
  const toggleSidebar = useCallback(() => {
    if (!state.displaySidebar) {
      pageGTMEvent({
        event: 'cart_recommend',
      })
    }
    return state.displaySidebar
      ? dispatch({ type: 'CLOSE_SIDEBAR' })
      : dispatch({ type: 'OPEN_SIDEBAR' })
  }, [dispatch, state.displaySidebar])
  const closeSidebarIfPresent = useCallback(
    () => state.displaySidebar && dispatch({ type: 'CLOSE_SIDEBAR' }),
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: 'OPEN_DROPDOWN' }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: 'CLOSE_DROPDOWN' }),
    [dispatch]
  )

  const openModal = useCallback(
    () => dispatch({ type: 'OPEN_MODAL' }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: 'CLOSE_MODAL' }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: 'SET_USER_AVATAR', value }),
    [dispatch]
  )
  const setRecaptcha = useCallback(
    (value: boolean) => dispatch({ type: 'SET_RECAPTCHA', value }),
    [dispatch]
  )
  const setGTM = useCallback(
    (value: boolean) => dispatch({ type: 'SET_GTM', value }),
    [dispatch]
  )
  const setSalePrice = useCallback(
    (value: number) => dispatch({ type: 'SET_SALEPRICE', value }),
    [dispatch]
  )
  const setOriginalPrice = useCallback(
    (value: number) => dispatch({ type: 'SET_ORIGINALPRICE', value }),
    [dispatch]
  )
  const setFirework = useCallback(
    (value: boolean) => dispatch({ type: 'SET_FIREWORK', value }),
    [dispatch]
  )
  const setTipModal = useCallback(
    (value: string) => dispatch({ type: 'SET_TIP_MODAL', value }),
    [dispatch]
  )
  const setPageType = useCallback(
    (value: string) => dispatch({ type: 'SET_PAGETYPE', value }),
    [dispatch]
  )
  const setCombination = useCallback(
    (value: Array<string>) => dispatch({ type: 'SET_COMBINATION', value }),
    [dispatch]
  )
  const setCombinationImg = useCallback(
    (value: Array<string>) => dispatch({ type: 'SET_COMBINATION_IMG', value }),
    [dispatch]
  )
  const setBundlePopup = useCallback(
    (value: boolean) => dispatch({ type: 'SET_BUNDLE_POPUP', value }),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: MODAL_VIEWS) => dispatch({ type: 'SET_MODAL_VIEW', view }),
    [dispatch]
  )

  const setCartAction = useCallback(
    (value) => dispatch({ type: 'SET_CARTACTION', value }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: SIDEBAR_VIEWS) => dispatch({ type: 'SET_SIDEBAR_VIEW', view }),
    [dispatch]
  )

  const setSwiperIndex = useCallback(
    (view: number) => dispatch({ type: 'SET_SWIPER_INDEX', view }),
    [dispatch]
  )

  const setMenuTheme = useCallback(
    (value: boolean) => dispatch({ type: 'SET_MENU_THEME', value }),
    [dispatch]
  )

  const setIsQuiz = useCallback(
    (value: boolean) => dispatch({ type: 'SET_IS_QUIZ', value }),
    [dispatch]
  )

  const setIsQuizDialog = useCallback(
    (value: boolean) => dispatch({ type: 'SET_IS_QUIZ_DIALOG', value }),
    [dispatch]
  )

  const setIsSignupDialog = useCallback(
    (value: boolean) => dispatch({ type: 'SET_IS_SIGNUP_DIALOG', value }),
    [dispatch]
  )

  const setPreviewData = useCallback(
    (value: boolean) => dispatch({ type: 'SET_PREVIEW_DATA', value }),
    [dispatch]
  )

  const setLstingCouponData = useCallback(
    (value: boolean) => dispatch({ type: 'SET_COUPONLISTING_DATA', value }),
    [dispatch]
  )

  const setContextTradeGift = useCallback(
    (value: boolean) => dispatch({ type: 'SET_TRADEGIFT_DATA', value }),
    [dispatch]
  )

  const setSelectedOptions = useCallback(
    (value: boolean) => dispatch({ type: 'SET_OPTIONS_DATA', value }),
    [dispatch]
  )

  const setCombineOption = useCallback(
    (value: boolean) => dispatch({ type: 'SET_COMBINEOPTION_DATA', value }),
    [dispatch]
  )

  const setCombinePrice = useCallback(
    (value: boolean) => dispatch({ type: 'SET_COMBINEPRICE_DATA', value }),
    [dispatch]
  )

  const setCombineVariant = useCallback(
    (value: boolean) => dispatch({ type: 'SET_COMBINEVARIANT_DATA', value }),
    [dispatch]
  )

  const setInViewData = useCallback(
    (value: boolean) => dispatch({ type: 'SET_INVIEWDOM_DATA', value }),
    [dispatch]
  )

  const setLoginPop = useCallback(
    (value: boolean) => dispatch({ type: 'SET_LOGIN_POP', value }),
    [dispatch]
  )

  const setLoginDefaultTab = useCallback(
    (value: string) => dispatch({ type: 'SET_LOGIN_DEFAULT_TAB', value }),
    [dispatch]
  )
  const setCountryModal = useCallback(
    (value: boolean) => dispatch({ type: 'SET_COUNTRYMODAL', value }),
    [dispatch]
  )
  const setRegistrationsPopup = useCallback(
    (value: boolean) => dispatch({ type: 'SET_REGISTRATIONS_POPUP', value }),
    [dispatch]
  )

  const setRegistrationsModelStatus = useCallback(
    (value: string) =>
      dispatch({ type: 'SET_REGISTRATIONS_MODEL_STATUS', value }),
    [dispatch]
  )

  const setCustomRedirectUrl = useCallback(
    (value: string) =>
      dispatch({ type: 'SET_REGISTRATIONS_CUSTOM_REDIRECT_URL', value }),
    [dispatch]
  )

  const setFbqTrack = useCallback(
    (value: string) => dispatch({ type: 'SET_REGISTRATIONS_FBQ_TRACK', value }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setSwiperIndex,
      setUserAvatar,
      setRecaptcha,
      setGTM,
      setSalePrice,
      setOriginalPrice,
      setFirework,
      setTipModal,
      setPageType,
      setCombination,
      setCombinationImg,
      setBundlePopup,
      setCartAction,
      setMenuTheme,
      setIsQuiz,
      setIsQuizDialog,
      setIsSignupDialog,
      setPreviewData,
      setInViewData,
      setContextTradeGift,
      setLstingCouponData,
      setSelectedOptions,
      setCombineOption,
      setCombinePrice,
      setCombineVariant,
      setLoginPop,
      setLoginDefaultTab,
      setCountryModal,
      setRegistrationsPopup,
      setRegistrationsModelStatus,
      setCustomRedirectUrl,
      setFbqTrack,
    }),
    [
      closeDropdown,
      closeModal,
      closeSidebar,
      closeSidebarIfPresent,
      openDropdown,
      openModal,
      setCartAction,
      openSidebar,
      setModalView,
      setRecaptcha,
      setGTM,
      setSalePrice,
      setOriginalPrice,
      setFirework,
      setTipModal,
      setPageType,
      setCombination,
      setCombinationImg,
      setBundlePopup,
      setSidebarView,
      setSwiperIndex,
      setUserAvatar,
      state,
      toggleSidebar,
      setMenuTheme,
      setIsQuizDialog,
      setIsQuiz,
      setIsSignupDialog,
      setPreviewData,
      setInViewData,
      setContextTradeGift,
      setLstingCouponData,
      setSelectedOptions,
      setCombineOption,
      setCombinePrice,
      setCombineVariant,
      setLoginPop,
      setLoginDefaultTab,
      setCountryModal,
      setRegistrationsPopup,
      setRegistrationsModelStatus,
      setCustomRedirectUrl,
      setFbqTrack,
    ]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext: FC = ({ children }) => (
  <UIProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
)
