import { useMemo } from 'react'
import { SWRHook } from '@commerce/utils/types'
import useProduct, { UseProduct } from '@commerce/product/use-product'

import { Product as ShopifyProduct, GetProductBySlugQuery } from '../schema'

import { getProductQuery, normalizeProduct } from '../utils'

import type { Product } from '@commerce/types/product'
import type { GetProductHook } from '../types/product'

export default useProduct as UseProduct<typeof handler>

/**
 * 客户端获取产品，多个产品用 , 隔开
 * 根据原 handle 数据返回，如果没有搜索到，该 handle 对应位置返回 {}
 * 比如 "a2147, a2668", 如果 a2668 没有找到，返回 {products:[{ handle:"a2147", ...}, {}]}
 */
export const handler: SWRHook<GetProductHook> = {
  fetchOptions: {
    query: getProductQuery,
  },

  async fetcher({ input, options, fetch }) {
    const { handles: originHandles } = input
    if (!originHandles) {
      return { products: [] }
    }

    const method = options?.method

    // swr 不支持数组，多个产品用 , 号分隔
    const handles = originHandles
      ?.split(',')
      ?.map((handle) => handle?.toLowerCase())
    // console.log('handles', handles)

    const noDuplicateHandles = new Set(handles)

    const promiseList: any = Array.from(noDuplicateHandles).map(
      (handle: string) => {
        return fetch<GetProductBySlugQuery>({
          query: options.query,
          locale: options.locale,
          method,
          variables: { slug: handle },
        })
      }
    )

    const results = await Promise.all(promiseList)

    const products = handles.map((handle) => {
      const result: any = results.find(
        (result: any) => result?.productByHandle?.handle === handle
      )
      if (result) {
        return normalizeProduct(
          result?.productByHandle as ShopifyProduct
        ) as Product
      } else {
        return {}
      }
    })

    return {
      products,
    }
  },
  useHook:
    ({ useData }) =>
    (input) => {
      const response = useData({
        input: [
          ['handles', input.handles],
          ['locale', input.locale],
        ],
        swrOptions: {
          revalidateOnFocus: false,
          ...input.swrOptions,
        },
      })
      return useMemo(() => Object.create(response), [response])
    },
}
