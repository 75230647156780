import Cookies, { CookieAttributes } from 'js-cookie'
import { SHOPIFY_COOKIE_EXPIRE, SHOPIFY_CUSTOMER_TOKEN_COOKIE } from '../const'

export const removeMultipassToken = () => {
  return Cookies.remove('multipassinfo')
}

export const getMultipassToken = () => {
  return Cookies.get('multipassinfo')
}

export const getCustomerToken = (locale: string) => {
  const checkoutTokenCookie: string = SHOPIFY_CUSTOMER_TOKEN_COOKIE[locale]

  let cookieResult = Cookies.get(checkoutTokenCookie)

  if (!cookieResult) {
    return ''
  }

  try {
    const multipassToken = getMultipassToken()
    const cookie = JSON.parse(decodeURIComponent(cookieResult))

    if (multipassToken && multipassToken === cookie.multipassToken) {
      return cookie.token
    }
  } catch (e) {}

  setCustomerToken(null, locale, {
    domain: window.location.hostname,
    sameSite: 'Strict',
    secure: true,
    expires: SHOPIFY_COOKIE_EXPIRE,
    path: '/',
  })

  return ''
}

export const setCustomerToken = (
  token: string | null,
  locale?: string,
  options?: CookieAttributes
) => {
  const LOCALE = locale || Cookies.get('current-language') || 'us'
  const checkoutTokenCookie: string = SHOPIFY_CUSTOMER_TOKEN_COOKIE[LOCALE]
  if (!token) {
    Cookies.remove(
      checkoutTokenCookie,
      options ?? {
        Domain: window.location.hostname,
        SameSite: 'Strict',
        Secure: true,
        expires: SHOPIFY_COOKIE_EXPIRE,
      }
    )
  } else {
    const multipassToken = getMultipassToken()

    if (!multipassToken) {
      return
    }

    Cookies.set(
      checkoutTokenCookie,
      {
        multipassToken,
        token,
      },
      options ?? {
        Domain: window.location.hostname,
        SameSite: 'Strict',
        Secure: true,
        expires: SHOPIFY_COOKIE_EXPIRE,
      }
    )
  }
}
