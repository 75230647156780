const features = {
  us: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: false,
  },
  uk: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
  },
  ca: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
  },
  'eu-de': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
  },
  'eu-en': {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
  },
  fr: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: true,
  },
  au: {
    cart: true,
    search: true,
    wishlist: false,
    customerAuth: true,
    customCheckout: false,
    showKlarna: false,
  },
  my: {
    cart: false,
    search: true,
    wishlist: false,
    customerAuth: false,
    customCheckout: false,
    showKlarna: false,
  },
  vn: {
    cart: false,
    search: true,
    wishlist: false,
    customerAuth: false,
    customCheckout: false,
    showKlarna: false,
  },
}

const I18N_STORE_DOMAIN =
  process.env.NEXT_PUBLIC_I18N_SHOPIFY_STORE_DOMAIN &&
  JSON.parse(process.env.NEXT_PUBLIC_I18N_SHOPIFY_STORE_DOMAIN)

const countries = features ? Object.keys(features) : ['us']

const locales = countries.filter((locale) => !!I18N_STORE_DOMAIN[locale])

module.exports = {
  provider: 'shopify',
  features,
  i18n: {
    locales,
    defaultLocale: 'us',
    localeDetection: false,
  },
  images: {
    domains: [
      'cdn.shopify.com',
      'cdn.shopifycdn.net',
      'www.facebook.com',
      'cdn4.fireworktv.com',
      'ct.pinterest.com',
    ],
    deviceSizes: [360, 768, 1080, 1200, 1920],
  },
}
