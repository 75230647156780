import { useCallback } from 'react'
import useCodeApply, { UseCodeApply } from '@commerce/cart/use-code-apply'
import type { MutationHook } from '@commerce/utils/types'
import type { CodeApplyHook } from '../types/cart'
import type  { CheckoutUserError, CheckoutErrorCode } from '../schema'
import checkoutDiscountCodeApplyMutation from '../utils/mutations/checkout-code-apply'
import {
  getCheckoutId,
  checkoutLineItemUpdateMutation,
  checkoutToCart,
  throwUserErrors
} from '../utils'
import useCart from './use-cart'

export default useCodeApply as UseCodeApply<typeof handler>

export const handler: MutationHook<CodeApplyHook> = {
  fetchOptions: {
    query: checkoutDiscountCodeApplyMutation,
  },
  async fetcher({ input: { discountCode }, options, fetch }) {
    const { locale = 'us' } = options;
    let checkoutId = getCheckoutId(locale)
    if (!checkoutId) return null
    const { checkoutDiscountCodeApplyV2 } = await fetch({
      ...options,
      variables: {
        checkoutId,
        discountCode,
      },
    })

    const result = checkoutToCart(checkoutDiscountCodeApplyV2, locale);

    const discountValue = result?.lineItems?.reduce((pre, cur) => {
      const code =
        cur.discountAllocations.length > 0 ? cur.discountAllocations[0] : {}
      return pre + Number(code?.allocatedAmount?.amount || 0)
    }, 0)
    if (!discountValue || discountValue<=0) {
      const checkoutUserErrors: Array<CheckoutUserError> = [
        {
          code: 'DiscountDisabled' as CheckoutErrorCode.DiscountDisabled,
          field: ['discountCode'],
          message: 'Unable to find a valid discount matching the code entered',
        },
      ]
      throwUserErrors(checkoutUserErrors)
    } 
    return result
  },
  useHook:
    ({ fetch }) =>
    () => {
      const { mutate } = useCart()
      return useCallback(
        async function codeApply(input) {
          const data = await fetch({ input })
          await mutate(data, false)
          return data
        },
        [mutate]
      )
    },
}
