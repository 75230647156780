import { I18N_STORE_DOMAIN, MULPASS_URL } from '@shopify/const'
import Cookies from 'js-cookie'
import { stringify } from 'query-string'

export function numberFormat(num: number = 0) {
  if (typeof Intl === 'undefined') {
    return num
  }

  return new Intl.NumberFormat().format(num)
}

export function getApp(locale: string = '') {
  return I18N_STORE_DOMAIN[locale || ''].split('.').shift()
}

export function getAlpcPath(locale = '') {
  let isEU = false
  const alpcEUCookie = Cookies.get('alpcEU')
  if (alpcEUCookie === undefined || alpcEUCookie === '') {
    if (['de', 'eu-de', 'eu', 'eu-en', 'uk', 'fr', 'fra'].includes(locale)) {
      isEU = true
    }
  } else {
    let alpcEU = Number(alpcEUCookie)
    if (alpcEU) {
      isEU = true
    }
  }

  if (isEU) {
    return '/api/multipass/alpc-eu'
  }

  return '/api/multipass/alpc'
}

export function getSignInUrl(locale: string = '') {
  return (
    MULPASS_URL +
    '?' +
    stringify({
      app: getApp(locale),
      redirect: location.href,
    })
  )
}

export function getSignUpUrl(locale: string = '') {
  return (
    MULPASS_URL +
    '?' +
    stringify({
      app: getApp(locale),
      redirect: location.href,
      tab: 'register',
    })
  )
}

export function getProfileUrl(locale: string = '') {
  return (
    MULPASS_URL +
    '/account/profile?' +
    stringify({
      app: getApp(locale),
      redirect: location.href,
    })
  )
}
