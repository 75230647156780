import { useHook, useSWRHook } from '../utils/use-hook'
import { SWRFetcher } from '../utils/default-fetcher'
import type { HookFetcherFn, SWRHook } from '../utils/types'
import type { SearchHook } from '@commerce/types/search'
import type { Provider } from '..'

export type UseSearch<H extends SWRHook<SearchHook> = SWRHook<SearchHook>> =
  ReturnType<H['useHook']>

export const fetcher: HookFetcherFn<SearchHook> = SWRFetcher

const fn = (provider: Provider) => provider.products?.useSearch!

const useSearch: UseSearch = (input) => {
  const hook = useHook(fn)
  return useSWRHook({ fetcher, ...hook })(input)
}

export default useSearch
