export async function getAsyncError(res) {
  try {
    const result = await res.json()
    return {
      status: res.status,
      statusText: res.statusText,
      errors:
        result?.data?.error ||
        result?.data?.code ||
        res.statusText ||
        res.status,
      type: result?.data?.type,
      data: result?.data,
      message: result?.data?.message,
    }
  } catch (error) {
    console.log(error)
  }
}

const handleFetchResponse = async (res) => {
  if (res.ok) {
    try {
      const result = await res.json()
      const { errors } = result
      if (errors && errors.length) {
        return { errors, status: res.status }
      }

      return result
    } catch (error) {
      console.log(error)
    }
  }

  return await getAsyncError(res)
}

export default handleFetchResponse
