import { useCallback } from 'react'
import type {
  MutationHookContext,
  HookFetcherContext,
} from '@commerce/utils/types'
import useRemoveCode, { UseRemoveCode } from '@commerce/cart/use-remove-code'
import type { Cart, RemoveCodeHook } from '../types/cart'
import useCart from './use-cart'

export default useRemoveCode as UseRemoveCode<typeof handler>

import {
  checkoutDiscountCodeRemoveMutation,
  getCheckoutId,
  checkoutToCart,
} from '../utils'

export const handler = {
  fetchOptions: {
    query: checkoutDiscountCodeRemoveMutation,
  },
  async fetcher({
    input,
    options,
    fetch,
  }: HookFetcherContext<RemoveCodeHook>) {
    const { locale } = options;
    const data = await fetch({
      ...options,
      variables: { checkoutId: getCheckoutId(locale) },
    })
    return checkoutToCart(data.checkoutDiscountCodeRemove, locale)
  },
  useHook:
    ({ fetch }: MutationHookContext<RemoveCodeHook>) => () => {
      const { mutate } = useCart()
      return useCallback(
        async function removeCode(input) {
          const data = await fetch({ input })
          await mutate(data, false)
          return data
        },
        [mutate]
      )
    },
}
