/**
 * @param children: children dom
 * @param animateKey: frame-motion key
 * @param className: 需要混入样式的Class
 * @param cns<Object>: 需要混入样式的多个 Class对象集合
 * @param border<Boolean>: 是否带边框
 * @param small<Boolean>: 是否为短按钮
 * @param theme<String>: [dark, light, brand] 三种固定样式
 * @param variant<String>: [button,link] dom
 * @returns
 */

import { useState } from 'react'
import cn from 'classnames'
import { LoadingDots } from '@components/ui'
import { motion } from 'framer-motion'

import s from './Button.module.css'

const Button = ({
  children,
  loading = false,
  animateKey = '',
  className = '',
  cns = {},
  style = {},
  border = false,
  theme = '',
  small = false,
  variant = 'button',
  variants = {},
  disabled = false,
  squareBorder = false,
  ...rest
}) => {
  // console.log('button disabled:', disabled)

  const setAnimates = (variants) => {
    const animates = {}
    Object.keys(variants).forEach((item, i) => {
      animates[item] = item
    })
    return animates
  }

  const setTheme = (theme) => {
    if (theme?.toLowerCase() === 'dark') return s.dark
    if (theme?.toLowerCase() === 'white') return s.white
    if (theme?.toLowerCase() === 'brand') return s.brand
    if (theme?.toLowerCase() === 'disable') return s.disable
    return s.light
  }

  const props = {
    key: animateKey || children,
    style: style || {},
    initial: 'initial',
    whileHover: 'hover',
    disabled: disabled ?? loading,
    className: cn(s.root, className, setTheme(theme), 'solidBtn', {
      [s.small]: small,
      [s.wireBtn]: border,
      [s.solidBtn]: !border,
      [s.disabled]: disabled,
      [s.squareBorder]: squareBorder,
    }),
    ...(variants ? { ...setAnimates(variants), variants } : {}),
    ...rest,
  }

  switch (variant) {
    case 'link':
      return (
        <motion.a {...props}>
          <Inner loading={loading} cns={cns}>
            {children}
          </Inner>
        </motion.a>
      )
    default:
      return (
        <motion.button {...props}>
          <Inner loading={loading} cns={cns}>
            {children}
          </Inner>
        </motion.button>
      )
  }
}

const Inner = ({ loading, cns, children }) => {
  return (
    <>
      <span className={cn(s.mask, cns?.mask, 'mask')}></span>
      <span
        className={cn(s.shadowBox, {
          // [s.btnShadow]: onHover,
        })}
      ></span>
      <div className={cn(s.content, cns?.content, 'contentBtn')}>
        {loading ? (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingDots />
          </div>
        ) : (
          children
        )}
      </div>
    </>
  )
}

export default Button
