import metafieldsItems from './metafields-items-query'

const getProductQuery = /* GraphQL */ `
  query getProductBySlug($slug: String!) {
    productByHandle(handle: $slug) {
      id
      handle
      availableForSale
      title
      productType
      vendor
      description
      descriptionHtml
      onlineStoreUrl
      publishedAt
      totalInventory
      tags
      seo {
        title
        description
      }
      options {
        id
        name
        values
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      collections(first: 250, reverse: true) {
        nodes {
          id
          title
          handle
        }
      }
      variants(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            title
            sku
            barcode
            availableForSale
            requiresShipping
            quantityAvailable
            currentlyNotInStock
            selectedOptions {
              name
              value
            }
            image {
              altText
              url
              width
              height
            }
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            MF_listing: metafield(namespace: "tags", key: "listing") {
              ${metafieldsItems}
            }
            MF_presell: metafield(namespace: "global", key: "presell") {
							${metafieldsItems}
            }
            MF_cartInfo: metafield(namespace: "cart", key: "info") {
							${metafieldsItems}
            }
            MF_image: metafield(namespace: "banner", key: "image") {
							${metafieldsItems}
            }
            MF_images: metafield(namespace: "variant", key: "images") {
							${metafieldsItems}
            }
            MF_infos: metafield(namespace: "variant", key: "infos") {
							${metafieldsItems}
            }
            MF_logistics: metafield(namespace: "variant", key: "logistics") {
							${metafieldsItems}
            }
            MF_variantSurprisePack: metafield(namespace: "variant", key: "surprisePack") {
              ${metafieldsItems}
            }
            MF_variantAccessories: metafield(namespace: "variant", key: "accessories") {
              ${metafieldsItems}
            }
            MF_discounts: metafield(namespace: "discounts", key: "discounts") {
							${metafieldsItems}
            }
            MF_priceDrop: metafield(namespace: "qty_level", key: "price_drop") {
							${metafieldsItems}
            }
            MF_discounts: metafield(namespace: "discounts", key: "discounts") {
							${metafieldsItems}
            }
            MF_gallery: metafield(namespace: "variant", key: "gallery") {
              ${metafieldsItems}
            }
            MF_freeGift: metafield(namespace: "free_gift", key: "freeGift") {
              ${metafieldsItems}
            }
            MF_tradeGift: metafield(namespace: "free_gift", key: "tradeGift") {
              ${metafieldsItems}
            }
            MF_scriptExchangePurchase: metafield(namespace: "scriptDiscount", key: "exchangePurchase") {
              ${metafieldsItems}
            }
            MF_variantTags: metafield(namespace: "filter", key: "tags") {
              ${metafieldsItems}
            }
            MF_memberBenefits: metafield(namespace: "activity", key: "memberBenefits") {
              ${metafieldsItems}
            }
            MF_supportProduct: metafield(namespace: "activity", key: "supportProduct") {
              ${metafieldsItems}
            }
          }
        }
      }
      images(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            originalSrc
            altText
            width
            height
          }
        }
      }
      MF_pageType: metafield(namespace: "pageType", key: "category") {
        ${metafieldsItems}
      }
      MF_seoSetting: metafield(namespace: "seo", key: "setting") {
        ${metafieldsItems}
      }
      MF_preRender: metafield(namespace: "build", key: "preRender") {
        ${metafieldsItems}
      }
      MF_relatedProducts: metafield(namespace: "relatedSource", key: "products") {
        ${metafieldsItems}
      }
      MF_template: metafield(namespace: "template", key: "name") {
        ${metafieldsItems}
      }
      MF_navSetting: metafield(namespace: "nav", key: "setting") {
        ${metafieldsItems}
      }
      MF_topBar: metafield(namespace: "product", key: "topbar") {
        ${metafieldsItems}
      }
      MF_gallery: metafield(namespace: "product", key: "gallery") {
        ${metafieldsItems}
      }
      MF_productType: metafield(namespace: "product", key: "type") {
        ${metafieldsItems}
      }
      MF_shopifyDescription: metafield(
        namespace: "product"
        key: "shopifyDescription"
      ) {
        ${metafieldsItems}
      }
      MF_productInfos: metafield(namespace: "product", key: "infos") {
        ${metafieldsItems}
      }
      MF_productTags: metafield(namespace: "infos", key: "productTags") {
        ${metafieldsItems}
      }
      MF_productNote: metafield(namespace: "infos", key: "productNote") {
        ${metafieldsItems}
      }
      MF_combination: metafield(namespace: "Bundle", key: "combination") {
        ${metafieldsItems}
      }
      MF_recommendations: metafield(namespace: "Bundle", key: "recommendations") {
        ${metafieldsItems}
      }
      MF_productPackage: metafield(namespace: "product", key: "package") {
							${metafieldsItems}
      }
      MF_productAccessories: metafield(namespace: "product", key: "accessories") {
							${metafieldsItems}
      }
      MF_productSurprisePack: metafield(namespace: "product", key: "surprisePack") {
							${metafieldsItems}
      }
      MF_productGift: metafield(namespace: "product", key: "gift") {
							${metafieldsItems}
      }
      MF_productGiftPopUps: metafield(namespace: "product", key: "giftPopUps") {
        ${metafieldsItems}
      }
      MF_bundleProduct: metafield(
        namespace: "relatedSource"
        key: "bundleProduct"
      ) {
        ${metafieldsItems}
      }
      MF_orderLimits: metafield(namespace: "settings", key: "orderLimits") {
        ${metafieldsItems}
      }
      MF_topRecommended: metafield(namespace: "combine_options", key: "topRecommended") {
        ${metafieldsItems}
      }
      MF_component_order: metafield(namespace: "component", key: "_order") {
        ${metafieldsItems}
      }
      MF_judgemeBadge: metafield(namespace: "judgeme", key: "badge") {
        ${metafieldsItems}
      }
      MF_judgemeWidget: metafield(namespace: "judgeme", key: "widget") {
        ${metafieldsItems}
      }
      MF_content: metafield(namespace: "pages", key: "content") {
        ${metafieldsItems}
      }
      MF_discounts: metafield(namespace: "discounts", key: "discounts") {
        ${metafieldsItems}
      }
      MF_productNav: metafield(namespace: "product", key: "nav") {
        ${metafieldsItems}
      }
      MF_optionsData: metafield(namespace: "product", key: "options") {
        ${metafieldsItems}
      }
      MF_popCompareData: metafield(namespace: "product", key: "popCompareData") {
        ${metafieldsItems}
      }
      MF_yotpoReviewsCount: metafield(namespace: "yotpo", key: "reviews_count") {
        ${metafieldsItems}
      }
      MF_yotpoReviewsAverage: metafield(namespace: "yotpo", key: "reviews_average") {
        ${metafieldsItems}
      }
      MF_pageCommon: metafield(namespace: "page", key: "common") {
        ${metafieldsItems}
      }
      MF_combineoptions_commoninfo: metafield(namespace: "combine_options", key: "common_info") {
        ${metafieldsItems}
      }
      MF_combineoptions_componentorder: metafield(namespace: "combine_options", key: "component_order") {
        ${metafieldsItems}
      }
      MF_combineoptions_order: metafield(namespace: "combine_options", key: "order") {
        ${metafieldsItems}
      }
      MF_option_country: metafield(namespace: "combine_options", key: "option_country") {
        ${metafieldsItems}
      }
      MF_esgdata: metafield(namespace: "combine_options", key: "esgdata") {
        ${metafieldsItems}
      }
      MF_saveupmodal: metafield(namespace: "combine_options", key: "save_up_modal") {
        ${metafieldsItems}
      }
    }
  }
`

export default getProductQuery
