import dynamic from 'next/dynamic'

const AmazonPay = dynamic(() => import('./AmazonPay'))
const Amex = dynamic(() => import('./Amex'))
const ApplePay = dynamic(() => import('./ApplePay'))
const DinersClub = dynamic(() => import('./DinersClub'))
const Discover = dynamic(() => import('./Discover'))
const GooglePay = dynamic(() => import('./GooglePay'))
const Mastercard = dynamic(() => import('./Mastercard'))
const Paypal = dynamic(() => import('./Paypal'))
const ShopPay = dynamic(() => import('./ShopPay'))
const Venmo = dynamic(() => import('./Venmo'))
const Visa = dynamic(() => import('./Visa'))

const icons = (props) => [
  {
    key: 'amazonPay',
    icon: <AmazonPay {...props}></AmazonPay>,
  },
  {
    key: 'amex',
    icon: <Amex {...props}></Amex>,
  },
  {
    key: 'applePay',
    icon: <ApplePay {...props}></ApplePay>,
  },
  {
    key: 'dinersClub',
    icon: <DinersClub {...props}></DinersClub>,
  },
  {
    key: 'discover',
    icon: <Discover {...props}></Discover>,
  },
  {
    key: 'googlePay',
    icon: <GooglePay {...props}></GooglePay>,
  },
  {
    key: 'mastercard',
    icon: <Mastercard {...props}></Mastercard>,
  },
  {
    key: 'paypal',
    icon: <Paypal {...props}></Paypal>,
  },
  {
    key: 'shopPay',
    icon: <ShopPay {...props}></ShopPay>,
  },
  {
    key: 'venmo',
    icon: <Venmo {...props}></Venmo>,
  },
  {
    key: 'visa',
    icon: <Visa {...props}></Visa>,
  },
]

export default icons
