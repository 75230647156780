export const getCustomerQuery = /* GraphQL */ `
  query getCustomer($customerAccessToken: String!, $ordersFirst: Int) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      displayName
      email
      phone
      tags
      acceptsMarketing
      createdAt
      orders(first: $ordersFirst) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            name
            email
            phone
            processedAt
            orderNumber
            subtotalPrice {
              amount
            }
            totalShippingPrice {
              amount
            }
            totalTax {
              amount
            }
            totalPrice {
              amount
            }
            currencyCode
            lineItems(first: 250) {
              edges {
                node {
                  title
                  quantity
                  variant {
                    id
                    sku
                    title
                    priceV2 {
                      amount
                      currencyCode
                    }
                    product {
                      id
                      title
                      tags
                    }
                  }
                }
              }
            }
            shippingAddress {
              address1
              address2
              city
              country
              countryCodeV2
              firstName
              lastName
              phone
              province
              provinceCode
              zip
            }
          }
        }
      }
      defaultAddress {
        address1
        address2
        city
        country
        countryCodeV2
        firstName
        lastName
        phone
        province
        provinceCode
        zip
      }
    }
  }
`
export default getCustomerQuery
