import common from './common'
import series from './series'
import collections from './collections'
import brands from './brands'
import apps from './apps'
import payment from './payment'

const Icon = ({ iconKey, ...rest }) => {
  let a11yProps = {}
  if ('onClick' in rest) {
    a11yProps.tabIndex = '0'
    a11yProps.role = 'button'
    a11yProps.onKeyPress = (e) => {
      if (e.key == 'Enter') {
        rest.onClick && rest.onClick()
      }
    }
  }
  const icons = [
    ...common({ ...a11yProps, ...rest }),
    ...series({ ...a11yProps, ...a11yProps, ...rest }),
    ...collections({ ...a11yProps, ...rest }),
    ...brands({ ...a11yProps, ...rest }),
    ...apps({ ...a11yProps, ...rest }),
    ...payment({ ...a11yProps, ...rest }),
  ]
  if (!iconKey) return null
  const iconObj = icons.find((icon) => icon.key === iconKey)
  if (iconObj) return <>{iconObj.icon}</>
  return null
}

export default Icon
