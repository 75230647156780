import dynamic from 'next/dynamic'

const TikTok = dynamic(() => import('./TikTok'))
const TikTokNew = dynamic(() => import('./TikTokNew'))
const Ins = dynamic(() => import('./Ins'))
const Twitter = dynamic(() => import('./Twitter'))
const Facebook = dynamic(() => import('./Facebook'))
const Youtube = dynamic(() => import('./Youtube'))
const InsNew = dynamic(() => import('./InsNew'))
const TwitterFooter = dynamic(() => import('./TwitterFooter'))
const FacebookFooter = dynamic(() => import('./FacebookFooter'))
const TwitterBlog = dynamic(() => import('./TwitterBlog'))
const FacebookBlog = dynamic(() => import('./FacebookBlog'))
const YoutubeNew = dynamic(() => import('./YoutubeNew'))
const Pinterest = dynamic(() => import('./Pinterest'))
const Email = dynamic(() => import('./Email'))

const icons = (props) => [
  {
    key: 'email',
    icon: <Email {...props}></Email>,
  },
  {
    key: 'pinterest',
    icon: <Pinterest {...props}></Pinterest>,
  },
  {
    key: 'tikTok',
    icon: <TikTok {...props}></TikTok>,
  },
  {
    key: 'tikTokNew',
    icon: <TikTokNew {...props}></TikTokNew>,
  },
  {
    key: 'ins',
    icon: <Ins {...props}></Ins>,
  },
  {
    key: 'insNew',
    icon: <InsNew {...props}></InsNew>,
  },
  {
    key: 'twitter',
    icon: <Twitter {...props}></Twitter>,
  },
  {
    key: 'twitterNew',
    icon: <TwitterFooter {...props}></TwitterFooter>,
  },
  {
    key: 'twitterBlog',
    icon: <TwitterBlog {...props}></TwitterBlog>,
  },
  {
    key: 'facebook',
    icon: <Facebook {...props}></Facebook>,
  },
  {
    key: 'facebookNew',
    icon: <FacebookFooter {...props}></FacebookFooter>,
  },
  {
    key: 'facebookBlog',
    icon: <FacebookBlog {...props}></FacebookBlog>,
  },
  {
    key: 'youtube',
    icon: <Youtube {...props}></Youtube>,
  },
  {
    key: 'youtubeNew',
    icon: <YoutubeNew {...props}></YoutubeNew>,
  },
]

export default icons
