import dynamic from 'next/dynamic'

const Phone = dynamic(() => import('./Phone'))
const Laptop = dynamic(() => import('./Laptop'))
const Tablet = dynamic(() => import('./Tablet'))
const Others = dynamic(() => import('./Others'))

const icons = (props) => [
  {
    "key": "phone",
    "icon": <Phone {...props}></Phone>
  },
  {
    "key": "laptop",
    "icon": <Laptop {...props}></Laptop>
  },
  {
    "key": "tablet",
    "icon": <Tablet {...props}></Tablet>
  },
  {
    "key": "others",
    "icon": <Others {...props}></Others>
  },
]

export default icons


